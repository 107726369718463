<!-- Footer -->
<footer class="footerContainer" (click)="scrollToTop($event)">
    <div class="container">
        <div class="footerLftRgt">
            <div class="lftFooter">
                <div>
                    <b (click)="companyMenu = !companyMenu" [ngClass]="{'show':companyMenu && isMobile}">Company</b>
                    <div class="footer-links" [ngClass]="{'show':companyMenu && isMobile}">
                      <a [routerLink]="['/about-us']">About Us</a>
                    </div>
                    <b (click)="resourcesMenu = !resourcesMenu" [ngClass]="{'show':resourcesMenu && isMobile}">Resources</b>
                    <div class="footer-links" [ngClass]="{'show':resourcesMenu && isMobile}">
                      <a href="http://blog.mypat.in/" target="_blank">Blog</a>
                    </div>
                </div>                
                <div>
                    <b (click)="examsMenu = !examsMenu" [ngClass]="{'show':resourcesMenu && isMobile}">Exams</b>
                    <div class="footer-links" [ngClass]="{'show':examsMenu && isMobile}">
                      <a [routerLink]="['/exam/jee-main']">JEE Main</a>
                      <a [routerLink]="['/exam/jee-advanced']">JEE Advanced</a>
                      <a [routerLink]="['/exam/bitsat']">BITSAT</a>
                      <a [routerLink]="['/exam/ntse']">NTSE</a>
                    </div>
                </div>
                <div>
                    <b (click)="previousPapersMenu = !previousPapersMenu" [ngClass]="{'show':previousPapersMenu && isMobile}">Previous Papers</b>
                    <div class="footer-links" [ngClass]="{'show':previousPapersMenu && isMobile}">
                      <a [routerLink]="['/previous-year-paper-download/iit-jee']">JEE Main &amp; Advanced</a>
                      <a [routerLink]="['/previous-year-paper-download/ntse']">NTSE</a>
                      <!--<a [routerLink]="['/previous-year-paper-download/boards']">Board Sample Paper</a>-->
                    </div>
                </div>
            </div>
            <div class="rgtFooter">
                <div>
                    <b (click)="eBooksMenu = !eBooksMenu" [ngClass]="{'show':eBooksMenu && isMobile}">E-Books</b>
                    <div class="footer-links" [ngClass]="{'show':eBooksMenu && isMobile}">
                      <a *ngFor="let book of ebook" (click)="redirectToEbooks(book)" class="ebooks">{{book.name}}</a>
                    </div>
                </div>              
                <div>
                    <b (click)="toolsMenu = !toolsMenu" [ngClass]="{'show':toolsMenu && isMobile}">Tools</b>
                    <div class="footer-links" [ngClass]="{'show':toolsMenu && isMobile}">
                        <a [routerLink]="'/college-predictor/jee-advanced'">JEE (A) College Predictor</a>
                        <a [routerLink]="'/college-predictor/jee-main'">JEE (M) College Predictor</a>
                        <a [routerLink]="'/prep-tracker/jee-main'">JEE Main preparation Tracker</a>
                        <a [routerLink]="'/prep-tracker/jee-advanced'">JEE Advanced preparation Tracker</a>
                        <a [routerLink]="'/prep-tracker/bitsat'">BITSAT preparation Tracker</a>
                        <a [routerLink]="'/prep-tracker/ntse'">NTSE preparation Tracker</a>
                    </div>
                </div>  
                <div class="reach-us-desktop-view">
                    <b (click)="reachUsMenu = !reachUsMenu" [ngClass]="{'show':reachUsMenu && isMobile}">Reach Us At</b>
                    <ul class="reach-listing" [ngClass]="{'show':reachUsMenu && isMobile}">
                      <li>
                          <img alt="Email" src="/assets/images/new-home/mail.svg">
                          <p>Email: <a href="mailto:support@mypat.in">support@mypat.in</a></p>
                      </li>
                      <li>
                          <img alt="Phone" src="/assets/images/new-home/phone-call.svg">
                          <p>
                              <strong><a href="tel:1800-121-0206">1800-121-0206</a></strong> (Toll Free)<br>
                              10 A.M. - 7 P.M. (All 7 Days)<br>*Except National Holidays
                          </p>
                      </li>
                      <li>
                          <img alt="Map" src="/assets/images/new-home/map-pin.svg">
                          <p>
                              <strong>Address</strong><br>
                              32-A, Chaudhary House,<br>Opp Vijay Mandal Enclave Gate<br>No 1, Kalu Sarai, Hauz Khas,<br> New Delhi 110016
                          </p>
                      </li>
                  </ul>
                </div>
            </div>
            <a class="footGplayIcon" href="https://play.google.com/store/apps/details?id=com.mypat" target="_blank"><img src="/assets/images/new-home/gplayIconBlack.svg"></a>
        </div>
        <div class="reach-us-mobile-view">
          <b>Reach Us At</b>
          <ul class="reach-listing">
            <li>
                <img alt="Email" src="/assets/images/new-home/mail.svg">
                <p>Email: <a href="mailto:support@mypat.in">support@mypat.in</a></p>
            </li>
            <li>
                <img alt="Phone" src="/assets/images/new-home/phone-call.svg">
                <p>
                    <strong><a href="tel:1800-121-0206">1800-121-0206</a></strong> (Toll Free)<br>
                    10 A.M. - 7 P.M. (All 7 Days)<br>*Except National Holidays
                </p>
            </li>
            <li>
                <img alt="Map" src="/assets/images/new-home/map-pin.svg">
                <p>
                    <strong>Address</strong><br>
                    32-A, Chaudhary House, Opp <br>Vijay Mandal Enclave Gate<br>No 1, Kalu Sarai, Hauz Khas,<br> New Delhi 110016
                </p>
            </li>
          </ul>
        </div>
        <div class="copySocial">
            <div class="copyRight">© 2024-2025 FIITJEE. All Rights Reserved</div>
            <div class="infoPagesLink">
                <a class="terms" routerLink="/terms-of-use">Terms of Use</a>
                <a class="privacy" routerLink="/privacy-policy">Privacy Policy</a>
                <a class="copy" routerLink="/copyright">Copyright</a>
                <a class="disc" routerLink="/disclaimer">Disclaimer</a>
            </div>
            <div class="socialLinks">
                <a class="facebook" [href]="FOOTERURL.facebook" target="_blank"><img src="/assets/images/new-home/socialFBIcon.svg" alt="facebook" /></a>
                <a class="youtube" [href]="FOOTERURL.youTube" target="_blank"><img src="/assets/images/new-home/socialYTIcon.svg" alt="youtube" /></a>
                <a class="instagram" [href]="FOOTERURL.instagram" target="_blank"><img src="/assets/images/new-home/socialINSTAIcon.svg" alt="instagram" /></a>
                <a class="linkedin" [href]="FOOTERURL.linkedIn"
                target="_blank"><img src="/assets/images/new-home/socialLIIcon.svg" alt="linkedin" /></a>
                <a class="twitter" [href]="FOOTERURL.twitter" target="_blank"><img src="/assets/images/new-home/socialTWIcon.svg" alt="twitter" /></a>
            </div>
        </div>
    </div>
  </footer>
  <!-- Footer -->